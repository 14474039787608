import { useEffect, useImperativeHandle } from 'react';
import { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import { useGetAmendmentByIdQuery } from '@xemplo/amendment-query';
import { ChangeTaskTypes } from '@xemplo/gp-types';
import { getAssignedWorkerDisplayName, getRequestedByUserName } from '@xemplo/gp-utils';

import { EditAmendmentFormValues, EditAmendmentProps } from './edit-amendment-form.types';

export const useEditAmendmentForm = (
  props: EditAmendmentProps & {
    ref: React.ForwardedRef<UseFormReturn<EditAmendmentFormValues>>;
  }
) => {
  const { data } = useGetAmendmentByIdQuery({ id: props.amendmentId });
  const {
    assignTo,
    requestedBy,
    lumpSumCalculationMethod,
    lumpSumNumberOfPayPeriods,
    amount,
    units,
    note,
    amendmentTaskTypeID,
  } = data?.result ?? {};

  const displayWorkerName = getAssignedWorkerDisplayName(assignTo);
  const requestedByName = getRequestedByUserName(requestedBy);

  const lumpSumValue =
    lumpSumCalculationMethod || lumpSumNumberOfPayPeriods
      ? 'Yes' // if either of the fields are present, we can infer that lump sum is Yes
      : 'No';

  const methods = useForm<EditAmendmentFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      rate: amount ?? undefined,
      units: units ?? undefined,
      subType: amendmentTaskTypeID ?? undefined,
      lumpSum: lumpSumValue,
      lumpSumCalculationMethod: lumpSumCalculationMethod ?? undefined,
      lumpSumNumberOfPayPeriods: lumpSumNumberOfPayPeriods ?? undefined,
      note: note ?? undefined,
    },
  });

  useImperativeHandle(props.ref, () => methods, [methods]);

  const watchLumpSum = methods.watch('lumpSum');
  const watchTaxCalculationmMethod = methods.watch('lumpSumCalculationMethod');

  const showLumpSumOptions = amendmentTaskTypeID === ChangeTaskTypes.ALLOWANCE;
  const showLumpSumCalculationMethod = watchLumpSum === 'Yes';
  const showPaymentPeriods = watchTaxCalculationmMethod === 'A';

  const resetLumpSumFields = useCallback(() => {
    methods.setValue('lumpSumCalculationMethod', undefined, { shouldDirty: true });
    methods.setValue('lumpSumNumberOfPayPeriods', undefined, { shouldDirty: true });
  }, [methods]);

  useEffect(() => {
    if (watchLumpSum === 'No') {
      resetLumpSumFields();
    }
  }, [resetLumpSumFields, watchLumpSum]);
  return {
    methods,
    showLumpSumOptions,
    showLumpSumCalculationMethod,
    showPaymentPeriods,
    displayWorkerName,
    requestedByName,
    lumpSumValue,
    watchLumpSum,
    watchTaxCalculationmMethod,
    amendment: data?.result,
  };
};
